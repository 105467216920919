<template>
  <div v-if="!unMountComponent">
    <banner :list="list"></banner>
    <menu-tab
      :menu="menu"
      :slidesPerView="2"
      :initIndex="index"
      @change="selectMenu"
    ></menu-tab>
    <list
      :url="strainurl"
      :filterFunc="strainOrCellsMap"
      :show="currentMenuItem == '微生物菌种'"
    >
      <template v-slot:default="data">
        <product-card :list="data" name="bnccdetail"></product-card>
      </template>
    </list>
    <list
      :url="cellsurl"
      :filterFunc="strainOrCellsMap"
      :show="currentMenuItem == '科研细胞'"
    >
      <template v-slot:default="data">
        <product-card :list="data" name="bnccdetail"></product-card>
      </template>
    </list>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  ref,
  watch,
  getCurrentInstance,
} from "vue";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/bnccProductCard.vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import MenuTab from "@/components/common/MenuTab.vue";
//  utils
import api from "@/api/axios";
//ts
import { productMap, strainOrCellsMap } from "@/hooks/useProduct";
import { nextTick } from "q";

export default defineComponent({
  name: "Bncc",
  components: {
    banner,
    list,
    productCard,
    MenuTab,
  },
  setup() {
    const list = ref(null);
    const productList = ref(null);
    const strainurl = "/M/Server/GetProductLastjz";
    const cellsurl = "/M/Server/GetProductLastxb";
    const productparams = {
      anclassID: 1,
    };

    api.get("/M/Product").then((res): void => {
      list.value = res.data.obj.listimg;
    });

    //  切换列表菜单
    type Menu = "微生物菌种" | "科研细胞";
    const menu: Menu[] = ["微生物菌种", "科研细胞"];

    const route = useRoute();
    const router = useRouter();

    const index = ref(0);
    const currentMenuItem = ref(menu[index.value]);
    function initShowTabData() {
      const type = route.query.type;
      switch (type) {
        case "0":
          index.value = 0;
          route.meta.searchType = "bncc";
          break;
        case "1":
          index.value = 1;
          route.meta.searchType = "ceils";
          break;
        default:
          index.value = 0;
          route.meta.searchType = "bncc";
          break;
      }

      currentMenuItem.value = menu[index.value];
    }
    initShowTabData();
    function selectMenu(menuItem: Menu) {
      currentMenuItem.value = menuItem;
      switch (menuItem) {
        case "微生物菌种":
          router.replace({
            name: "Bncc",
            query: {
              type: 0
            }
          })
          break;
        case "科研细胞":
          router.replace({
            name: "Bncc",
            query: {
              type: 1
            }
          })
          break;
        default:
          router.replace({
            name: "Bncc",
            query: {
              type: 0
            }
          })
          break;
      }

    }
    onActivated(() => {
      initShowTabData();
      console.log(index.value);
      console.log(currentMenuItem.value);
    });
    watch(
      () => {
        return route.query;
      },
      (to, form) => {
        console.log(to, form);
        switch (to.type) {
          case "0":
            index.value = 0;
            currentMenuItem.value = menu[0];
            route.meta.searchType = "bncc";
            break;
          case "1":
            index.value = 1;
            currentMenuItem.value = menu[1];
            route.meta.searchType = "ceils";
            break;
          default:
            index.value = 0;
            currentMenuItem.value = menu[0];
            route.meta.searchType = "bncc";
        }
      }
    );

    const unMountComponent = ref(false);
    onBeforeRouteLeave((to, from) => {
      if (to.name != "bnccdetail") {
        unMountComponent.value = true;
        nextTick(() => {
          unMountComponent.value = false;
        });
      }
      return true;
    });
    if(localStorage.getItem('WX')){
      setTimeout(function () {
        location.reload();
      },1000)
    }
    localStorage.removeItem('WX')

    return {
      list,
      productList,
      menu,
      index,
      currentMenuItem,
      selectMenu,
      strainurl,
      cellsurl,
      productMap,
      strainOrCellsMap,
      productparams,
      unMountComponent,
    };
  },
});
</script>

<style lang="scss" scoped>
//  list  nav
.menu-nav-wapper {
  overflow: hidden;
  height: 45px;
  line-height: 45px;
  border-bottom: 1.012px solid #f4f4f4;


  .menu-nav {
    overflow: hidden;
    height: 45px;
    width: 100%;
    line-height: 45px;
    border-bottom: 1px solid #f4f4f4;
    top: 0;
    z-index: 20;
    background-color: #fff;
  }
  li {
    float: left;
    width: 50%;
    text-align: center;
    a {
      display: block;
      text-align: center;
      line-height: 45px;
      width: 100%;
      font-size: 16px;
      color: #666;
    }
  }

  .active a {
    color: #df0024;
  }
}
</style>
